export const editItems = [
    {
        name: 'redirect_to',
        component: 'input',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Redirect To',
        placeholder: 'Add Redirect To',
        legend: 'Redirect To'
    },
    {
        name: 'price',
        component: 'number',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Price',
        placeholder: 'Price',
        legend: 'Price'
    },
    {
        name: 'category_id',
        component: 'category-select',
        style: 'width: 70%; margin-right: 10px',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Category',
        placeholder: 'Select Category',
        legend: 'Category'
    },
    {
        name: 'locale',
        component: 'select',
        style: 'width: calc(30% - 10px)',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Locale',
        placeholder: 'Select Locale',
        legend: 'Locale'
    },
    {
        name: 'cover_image_url',
        component: 'img',
        previewComponent: 'preview-img-field',
        hideComponent: false,
        label: 'Upload image here',
        placeholder: 'Upload image here',
        legend: 'Upload image'
    },
    {
        name: 'post_slug',
        component: 'slug',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Slug',
        placeholder: 'Slug',
        legend: 'Slug'
    }
]
