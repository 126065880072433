import {
    maxLength,
    minLength,
    required
} from 'vuelidate/lib/validators';

import { validationBaseRules } from '../revissionsRules'

export const validationRevisionRules = {
    form_data: {
        ...validationBaseRules,
        post_title_secondary: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(65)
        },
        post_title_primary: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(100)
        },
        post_keyword_singular: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(30)
        },
        post_keyword_plural: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(30)
        },
        post_button_keyword: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(30)
        },
        post_content_secondary: {
            required,
            minLength: minLength(200)
            // checkForH1(value) {
            //     return !(value.includes('<h1') || value.includes('<H1'))
            // }
        },
        post_content_primary: {
            required,
            minLength: minLength(200),
            maxLength: maxLength(600)
        }
    }
}
