import {
    // maxLength,
    // minLength,
    required
} from 'vuelidate/lib/validators';

import { validationBaseRules } from '../editRules'

export const validationEditRules = {
    form_data: {
        ...validationBaseRules,
        price: {},
        post_slug: {
            required,
            async slugFormat(value) {
                if (!this.$v.form_data.post_slug.required || !value) return true;
                return new Promise((resolve) => {
                    if (/^[a-z0-9][a-z0-9-]+[a-z0-9]$/ig.test(value)) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
            },
            slugExists(value) {
                // ignore this validator, if any of the previous fail
                if (!this.$v.form_data.post_slug.required || !this.$v.form_data.post_slug.slugFormat || !value) return true
                return new Promise((resolve) => {
                    this.validateSlug(value)
                        .then(() => {
                            resolve(true)
                        })
                        .catch(() => {
                            this.$v.form_data.post_slug.$params.slugExists = false
                            console.log(this.$v.form_data.post_slug.$params.slugExists)
                            resolve(false)
                        })
                })
            }
        }
    }
}
