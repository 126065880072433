import {
    maxLength,
    minLength,
    required
} from 'vuelidate/lib/validators';

import { validationBaseRules as editRules } from '../editRules'
import { validationBaseRules as revissionsRules } from '../revissionsRules';

export const validationCreateRules = {
    form_data: {
        ...editRules,
        ...revissionsRules,
        price: {},
        post_title_secondary: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(65)
        },
        post_slug: {
            required,
            async slugFormat(value) {
                if (!this.$v.form_data.post_slug.required || !value) return true;
                return new Promise((resolve) => {
                    if (/^[a-z0-9][a-z0-9-]+[a-z0-9]$/ig.test(value)) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
            },
            slugExists(value) {
                // ignore this validator, if any of the previous fail
                if (!this.$v.form_data.post_slug.required || !this.$v.form_data.post_slug.slugFormat || !value) return true;
                return new Promise((resolve) => {
                    this.validateSlug(value)
                        .then(() => {
                            resolve(true)
                        })
                        .catch(() => {
                            this.$v.form_data.post_slug.$params.slugExists = false
                            resolve(false)
                        })
                })
            }
        },
        post_title_primary: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(100)
        },
        post_keyword_singular: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(30)
        },
        post_keyword_plural: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(30)
        },
        post_button_keyword: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(30)
        },
        post_content_secondary: {
            required,
            minLength: minLength(200)
            // checkForH1(value) {
            //     return !(value.includes('<h1') || value.includes('<H1'))
            // }
        },
        post_content_primary: {
            required,
            minLength: minLength(200),
            maxLength: maxLength(600)
        }
    }
}
