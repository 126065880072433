export const createItems = [
    {
        name: 'redirect_to',
        component: 'input',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Redirect To',
        placeholder: 'Add Redirect To',
        legend: 'Redirect To'
    },
    {
        name: 'price',
        component: 'number',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Price',
        placeholder: 'Price',
        legend: 'Price'
    },
    {
        name: 'category_id',
        component: 'category-select',
        style: 'width: 70%; margin-right: 10px',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Category',
        placeholder: 'Select Category',
        legend: 'Category'
    },
    {
        name: 'locale',
        component: 'select',
        style: 'width: calc(30% - 10px)',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Locale',
        placeholder: 'Select Locale',
        legend: 'Locale'
    },
    {
        name: 'cover_image_url',
        component: 'img',
        previewComponent: 'preview-img-field',
        hideComponent: false,
        label: 'Upload image here',
        placeholder: 'Upload image here',
        legend: 'Upload image'
    },
    {
        name: 'post_meta_title',
        component: 'input',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Meta Title tag',
        placeholder: 'Meta Title tag',
        legend: '<Meta Title> tag'
    },
    {
        name: 'post_title_secondary',
        component: 'input',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'H1 Title',
        placeholder: 'H1 Title',
        legend: 'H1 Title'
    },
    {
        name: 'post_slug',
        component: 'slug',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Slug',
        placeholder: 'Slug',
        legend: 'Slug'
    },
    {
        name: 'post_meta_description',
        component: 'textarea',
        previewComponent: 'preview-description-field',
        hideComponent: false,
        label: '<Meta Description> tag',
        placeholder: 'Meta Description tag',
        legend: '<Meta Description> tag'
    },
    {
        name: 'post_title_primary',
        component: 'input',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Primary Title',
        placeholder: 'Primary Title',
        legend: 'Primary Title'
    },
    {
        name: 'post_keyword_singular',
        component: 'input',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Keywords singular',
        placeholder: 'Keywords singular',
        legend: 'Keywords singular'
    },
    {
        name: 'post_keyword_plural',
        component: 'input',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Keywords plural',
        placeholder: 'Keywords plural',
        legend: 'Keywords plural'
    },
    {
        name: 'post_button_keyword',
        component: 'input',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Button Keywords',
        placeholder: 'Button Keywords',
        legend: 'Button Keywords'
    },
    {
        name: 'post_content_primary',
        component: 'textarea',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Description',
        placeholder: 'Description',
        legend: 'Description'
    },
    {
        name: 'post_content_secondary',
        component: 'content',
        previewComponent: 'preview-description-field',
        hideComponent: false,
        label: 'Content',
        placeholder: 'Content',
        legend: 'Content'
    }
]
